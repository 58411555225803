define([
    'lodash',
    'coreUtils',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/SiteMembersSantaTypes'
], function (_, coreUtils, PropTypes, santaComponents, propsSelectorsUtils, SiteMembersSantaTypes) {
    'use strict';

    const createSantaType = propsSelectorsUtils.createComponentSantaType;

    function getLoginButtonText(lang, textType) {
        return coreUtils.translationsLoader.getTranslation('loginButtonTranslations', lang, textType);
    }

    function getUserName(state, props) {
        const memberDetails = SiteMembersSantaTypes.memberDetails.fetch(state, props);
        if (!memberDetails) {
            return '';
        }

        return _.get(memberDetails.attributes, 'nickname') || _.get(memberDetails.attributes, 'name') || memberDetails.email;
    }

    const language = createSantaType(PropTypes.string, function (state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        return _.get(compData, 'language') || 'en';
    });

    const actionTitle = createSantaType(PropTypes.string, function (state, props) {
        const compLanguage = language.fetch(state, props);

        const isLoggedIn = SiteMembersSantaTypes.isLoggedIn.fetch(state, props);
        if (!isLoggedIn) {
            return getLoginButtonText(compLanguage, 'Login_Button_Sign_In');
        }
        return getLoginButtonText(compLanguage, 'Login_Button_Sign_Out');
    });

    const memberTitle = createSantaType(PropTypes.string, function (state, props) {
        const isLoggedIn = SiteMembersSantaTypes.isLoggedIn.fetch(state, props);
        const username = isLoggedIn && getUserName(state, props);

        if (username) {
            return `${getLoginButtonText(language.fetch(state, props), 'Login_Button_Hello')} ${username}`;
        }

        return '';
    });

    const readyToShow = createSantaType(PropTypes.bool, function (state, props) {
        const isLoggedIn = SiteMembersSantaTypes.isLoggedIn.fetch(state, props);
        return !isLoggedIn || getUserName(state, props).length > 0;
    });

    return {
        language,
        actionTitle,
        memberTitle,
        isReadyToShow: readyToShow
    };
});
