define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'HtmlPostMessageAspect';

    const registerComponent = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.registerComponent;
    }, 'registerComponent');

    const unRegisterComponent = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.unRegisterComponent;
    }, 'unRegisterComponent');

    return {
        registerComponent,
        unRegisterComponent
    };
});
