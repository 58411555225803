define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const getSliderGalleryMeasures = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getSliderGalleryMeasures;
    }, 'getSliderGalleryMeasures');

    const getPaginatedGridGalleryMeasures = createGlobalSantaType(PropTypes.func, function (state) {
        return function (id) {
            return {
                width: _.get(state.siteData, ['measureMap', 'width', id]),
                height: _.get(state.siteData, ['measureMap', 'height', id])
            };
        };
    }, 'getPaginatedGridGalleryMeasures');

    const getCustomMeasureMap = createGlobalSantaType(PropTypes.func, function (state) {
        return function (id) {
            return state.siteData.measureMap && state.siteData.measureMap.custom[id] || null; // eslint-disable-line no-mixed-operators
        };
    }, 'getCustomMeasureMap');

    const getWindowInnerHeight = createGlobalSantaType(PropTypes.func, function (state) {
        return function () {
            return _.get(state.siteData.measureMap, ['innerHeight', 'screen']);
        };
    }, 'getWindowInnerHeight');

    return {
        getSliderGalleryMeasures,
        getPaginatedGridGalleryMeasures,
        getCustomMeasureMap,
        getWindowInnerHeight
    };
});
