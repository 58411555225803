define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'AudioAspect';

    const isPlaying = createComponentSantaType(PropTypes.bool, function (state, props) {
        const aspectData = state.siteAPI.getAspectGlobalData(aspectName);
        return _.get(aspectData, ['nowPlayingComp']) === props.structure.id;
    });

    const isSoundManagerOnResetup = createGlobalSantaType(PropTypes.bool, function (state) {
        const aspectData = state.siteAPI.getAspectGlobalData(aspectName);
        return !!_.get(aspectData, ['isSoundManagerOnResetup']);
    }, 'isSoundManagerOnResetup');

    const soundManagerReady = createGlobalSantaType(PropTypes.bool, function (state) {
        const aspectData = state.siteAPI.getAspectGlobalData(aspectName);
        return !!_.get(aspectData, ['soundManagerReady']);
    }, 'soundManagerReady');

    const updatePlayingComp = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.updatePlayingComp;
    }, 'updatePlayingComp');

    const updatePausingComp = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.updatePausingComp;
    }, 'updatePausingComp');

    const createAudioObj = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.createAudioObj;
    }, 'createAudioObj');

    const onHTML5ErrorTryToReloadWithFlash = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.onHTML5ErrorTryToReloadWithFlash;
    }, 'onHTML5ErrorTryToReloadWithFlash');

    return {
        isPlaying,
        isSoundManagerOnResetup,
        soundManagerReady,
        createAudioObj,
        updatePlayingComp,
        updatePausingComp,
        onHTML5ErrorTryToReloadWithFlash
    };
});
