define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils'
], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const setDisplayedOnlyCompsTemplateId = propsSelectorsUtils.createGlobalSantaType(PropTypes.func, function (state) {
        const displayedDAL = state.siteAPI.getDisplayedDAL();
        const pointers = state.siteAPI.getPointers();

        return (repeaterId, itemId) => {
            if (!itemId) {
                return;
            }

            const templateIdPointer = pointers.displayedOnlyComponents.getComponentTemplateId(repeaterId);
            displayedDAL.set(templateIdPointer, itemId);
        };
    }, 'repeaterTypes_setDisplayedOnlyCompsTemplateId');

    const clearDisplayedOnlyCompsTemplateId = propsSelectorsUtils.createGlobalSantaType(PropTypes.func, function (state) {
        const displayedDAL = state.siteAPI.getDisplayedDAL();
        const pointers = state.siteAPI.getPointers();

        return repeaterId => {
            const isInitialized = displayedDAL.isExist(pointers.displayedOnlyComponents.getComponentTemplateId(repeaterId));
            if (!isInitialized) {
                return;
            }

            const templateIdPointer = pointers.displayedOnlyComponents.getComponentTemplateId(repeaterId);
            displayedDAL.remove(templateIdPointer);
        };
    }, 'repeaterTypes_clearDisplayedOnlyCompsTemplateId');

    return {
        setDisplayedOnlyCompsTemplateId,
        clearDisplayedOnlyCompsTemplateId
    };
});
