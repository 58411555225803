define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'anchorChangeEvent';

    const activeAnchorData = createGlobalSantaType(PropTypes.object, function (state) {
        const activeAnchor = state.siteAPI.getAspectGlobalData(aspectName);
        return {
            activeAnchor: activeAnchor || null
        };
    }, 'activeAnchorData');

    const activeAnchorId = createGlobalSantaType(PropTypes.object, function (state) {
        const aspectData = state.siteAPI.getAspectGlobalData(aspectName);
        return {
            activeAnchorId: _.get(aspectData, ['activeAnchorComp', 'id'], null)
        };
    }, 'activeAnchorId');

    return {
        activeAnchorData,
        activeAnchorId
    };
});
