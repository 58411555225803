define([
    'lodash',
    'coreUtils',
    'coreMultilingual',
    'santaProps/utils/propsSelectorsUtils'
], function (
    _,
    coreUtils,
    coreMultilingual,
    propsSelectorsUtils
) {
    'use strict';

    const {multilingual} = coreUtils;
    const {languageStatus} = coreMultilingual;

    function getDalAndPointers(fn) {
        return ({siteAPI}) => fn({
            dal: siteAPI.getDisplayedDAL(),
            pointers: siteAPI.getPointers()
        });
    }

    function applyPointers(getPointers, appliedFn = _.identity) {
        return getDalAndPointers(({dal, pointers}) => {
            const pointerData = getPointers(pointers).map(pointer => dal.get(pointer));
            return appliedFn(...pointerData);
        });
    }

    function namespacedPointerGet(namespace) {
        return (...pointerNames) => pointers => pointerNames.map(pointerName => _.get(pointers, [namespace, pointerName], _.constant([]))());
    }

    const multilingualPointersGet = namespacedPointerGet('multilingual');

    return {
        setCurrentLanguageCode: propsSelectorsUtils.createGlobalSantaTypesFetcher(({siteAPI}) => multilingual.setCurrentLanguage({
            siteDataAPI: siteAPI.getSiteDataAPI(),
            dal: siteAPI.getDisplayedDAL(),
            pointers: siteAPI.getPointers(),
            siteAPI
        }), 'setCurrentLanguageCode'),
        isEnabled: propsSelectorsUtils.createGlobalSantaTypesFetcher(
            applyPointers(
                multilingualPointersGet('originalLanguage'),
                originalLanguage => !_.isEmpty(originalLanguage)
            ),
            'isEnabled'
        ),

        languages: propsSelectorsUtils.createGlobalSantaTypesFetcher(
            applyPointers(
                multilingualPointersGet('translationLanguages', 'originalLanguage'),
                (translationLanguages = [], originalLanguage) =>
                    _(translationLanguages)
                        .concat(originalLanguage)
                        .compact()
                        .reject({status: languageStatus.DELETED})
                        .map(lang => lang.status ? lang : _.assign({status: languageStatus.ACTIVE}, lang))
                        .value()
            ),
            'languages'
        ),
        originalLanguage: propsSelectorsUtils.createGlobalSantaTypesFetcher(
            applyPointers(
                multilingualPointersGet('originalLanguage'),
                originalLanguage => originalLanguage
            ),
            'originalLanguage'
        ),
        currentLanguage: propsSelectorsUtils.createGlobalSantaTypesFetcher(
            applyPointers(
                multilingualPointersGet('currentLanguageCode', 'translationLanguages', 'originalLanguage'),
                (currentLanguageCode, translationLanguages = [], originalLanguage) =>
                    _(translationLanguages)
                        .concat(originalLanguage)
                        .find({languageCode: currentLanguageCode}) || {}),
            'currentLanguage'
        )
    };
});
