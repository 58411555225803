define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const aspectName = 'PinterestWidgetPostMessageAspect';


    const data = createComponentSantaType(PropTypes.object, function (state, props) {
        return state.siteAPI.getAspectComponentData(aspectName, props.structure.id) || {};
    });

    return {
        data
    };
});
