define(['lodash', 'mobx', 'prop-types'], function (_, mobx, PropTypes) {
    'use strict';

    function validateType(propName, typeDef, propValue) {
        PropTypes.checkPropTypes(
            {[propName]: typeDef},
            {[propName]: propValue},
            'prop',
            `Test ${propName} Component`,
            () => Error(`type of ${propName} does not match the definition type`).stack
        );
    }

    function validateAspectProps(propTypes, props) {
        _.forEach(propTypes, (checkType, propName) => validateType(propName, checkType, props[propName]));
    }

    return {
        connect: (Aspect, mapStateToProps, validateProps) => {
            class ConnectedAspect extends Aspect {
                constructor(state, ...args) {
                    const computed = mobx.computed(() => mapStateToProps(state));
                    const props = computed.get();
                    if (validateProps) {
                        validateAspectProps(Aspect.hostPropTypes, props);
                    }
                    super(props, ...args);
                    if (!state.getSiteAPI().getSiteData().isInSSR() && _.isFunction(super.onHostPropsChanged)) {
                        mobx.observe(computed, change => this.onHostPropsChanged(change.newValue));
                    }
                }

                onHostPropsChanged(props) {
                    if (validateProps) {
                        validateAspectProps(Aspect.hostPropTypes, props);
                    }
                    super.onHostPropsChanged(props);
                }
            }

            return ConnectedAspect;
        }
    };
});
