define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const externalBaseUrl = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state.siteData, 'publicModel.externalBaseUrl');
    }, 'externalBaseUrl');

    return {
        externalBaseUrl
    };
});
