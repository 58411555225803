define(['lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils'
],
function (_,
          PropTypes,
          propsSelectorsUtils) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;

    const renderer = createComponentSantaType(PropTypes.func, function (state) {
        return state.siteAPI.setPageJsonldImmediate;
    });

    return {
        renderer
    };
});

