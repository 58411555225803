define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils'
], function (
    _,
    PropTypes,
    propsSelectorsUtils
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const all = createGlobalSantaType(PropTypes.object, function (state) {
        const displayedDal = state.siteAPI.getDisplayedDAL();
        const pointers = state.siteAPI.getPointers();
        const renderFlagsPointer = pointers.general.getRenderFlags();
        return displayedDal.get(renderFlagsPointer);
    }, 'all');

    const createRenderFlagFetcherFromArray = function (flag, propType) {
        return createComponentSantaType(propType || PropTypes.bool, function (state, props) {
            return _.includes(state.siteAPI.getRenderFlag(flag), props.structure.id);
        });
    };

    const createRenderFlagFetcherByCompId = function (flag, propType) {
        return createComponentSantaType(propType || PropTypes.bool, function (state, props) {
            const displayedDal = state.siteAPI.getDisplayedDAL();
            return displayedDal.getByPath(['renderFlags', flag, props.structure.id]);
        });
    };

    const createRenderFlagFetcher = function (flag, propType) {
        return createGlobalSantaType(propType, function (state) {
            return state.siteAPI.getRenderFlag(flag);
        }, `renderFlag_${flag}`);
    };

    const createBooleanRenderFlagFetcher = function (flag) {
        return createGlobalSantaType(PropTypes.bool, function (state) {
            return !!state.siteAPI.getRenderFlag(flag);
        }, `renderFlag_${flag}`);
    };

    return {
        all, //TODO: PLEASE DO NOT USE. SHOULD BE REMOVED.

        hideSiteBackground: createBooleanRenderFlagFetcher('hideSiteBackground'),
        isComponentVisible: createRenderFlagFetcherByCompId('componentVisibility'),
        isZoomAllowed: createBooleanRenderFlagFetcher('isZoomAllowed'),
        isExternalNavigationAllowed: createBooleanRenderFlagFetcher('isExternalNavigationAllowed'),
        renderFixedPositionBackgrounds: createBooleanRenderFlagFetcher('renderFixedPositionBackgrounds'),
        isTinyMenuOpenAllowed: createBooleanRenderFlagFetcher('isTinyMenuOpenAllowed'),
        isSocialInteractionAllowed: createBooleanRenderFlagFetcher('isSocialInteractionAllowed'),
        shouldBlockGoogleMapInteraction: createBooleanRenderFlagFetcher('shouldBlockGoogleMapInteraction'),
        shouldResetTinyMenuZIndex: createBooleanRenderFlagFetcher('shouldResetTinyMenuZIndex'),
        shouldResetSlideShowNextPrevButtonsPosition: createBooleanRenderFlagFetcher('shouldResetSlideShowNextPrevButtonsPosition'),
        shouldResetSubscribeFormMinMaxWidth: createBooleanRenderFlagFetcher('shouldResetSubscribeFormMinMaxWidth'),
        isBackToTopButtonAllowed: createBooleanRenderFlagFetcher('isBackToTopButtonAllowed'),
        isSlideShowGalleryClickAllowed: createBooleanRenderFlagFetcher('isSlideShowGalleryClickAllowed'),
        shouldResetGalleryToOriginalState: createBooleanRenderFlagFetcher('shouldResetGalleryToOriginalState'),
        showControllers: createBooleanRenderFlagFetcher('showControllers'),
        siteScale: createRenderFlagFetcher('siteScale', PropTypes.number),
        isWixAdsAllowed: createBooleanRenderFlagFetcher('isWixAdsAllowed'),
        allowShowingFixedComponents: createBooleanRenderFlagFetcher('allowShowingFixedComponents'),
        showHiddenComponents: createBooleanRenderFlagFetcher('showHiddenComponents'),
        ignoreComponentCollapsedProperty: createRenderFlagFetcherFromArray('ignoreComponentsCollapsedProperty'),
        ignoreComponentHiddenProperty: createRenderFlagFetcherFromArray('ignoreComponentsHiddenProperty'),
        isComponentTransitionAllowed: createBooleanRenderFlagFetcher('isComponentTransitionAllowed')
    };
});
