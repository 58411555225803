define([
    'lodash',
    'santaProps/propsBuilder/propsBuilderUtil',
    'santaProps/propsBuilder/computedPropsBuilder',
    'santa-components'
], function (_, propsBuilderUtil, computedPropsBuilder, santaComponents) {
    'use strict';

    const LEGACY_PROPS_COMP_TYPES = {
        'wysiwyg.viewer.components.MediaRichText': true
    };

    function getRootProps(compClass, rootId, siteAPI, viewerPrivateServices, measureMap) {
        const siteData = siteAPI.getSiteData();
        const behaviorsAspect = siteAPI.getSiteAspect('behaviorsAspect');

        const displayedDAL = siteAPI.getDisplayedDAL();
        const pointers = siteAPI.getPointers();
        const pagePointer = pointers.components.getPage(rootId, siteData.getViewMode());

        const structure = displayedDAL.get(pagePointer);
        const currentPageComponentType = structure.componentType || 'mobile.core.components.MasterPage';

        const {propertyQuery, styleId, skin, behaviorQuery, dataQuery} = structure;
        const rootProps = {
            viewerPrivateServices,
            componentType: currentPageComponentType,
            siteData,
            siteAPI,
            id: rootId,
            key: `${rootId}_${siteData.getViewMode()}`,
            ref: rootId,
            refInParent: rootId,
            /**@deprecated */
            pageId: rootId,
            rootId,
            currentUrlPageId: siteData.getCurrentUrlPageId(),
            rootNavigationInfo: siteData.getExistingRootNavigationInfo(rootId),
            styleId: propsBuilderUtil.getStyleId(styleId, skin),
            skin: propsBuilderUtil.getSkin(styleId, skin, function (id) {
                return displayedDAL.get(pointers.data.getThemeItem(id, 'masterPage'));
            }),
            style: {
                width: '100%',
                height: '100%'
            },
            compProp: propsBuilderUtil.getCompProp(siteAPI, propertyQuery, rootId),
            compActions: behaviorsAspect.getActions('comp', rootId),
            compBehaviors: propsBuilderUtil.getCompBehaviors(siteAPI, propertyQuery, behaviorQuery, rootId, rootId),
            renderFlags: siteData.renderFlags
        };

        if (dataQuery) {
            rootProps.compData = propsBuilderUtil.getCompData(siteAPI, dataQuery, rootId);
        }

        rootProps.measureMap = measureMap;

        const santaPropsSelectorForComp = santaComponents.utils.santaTypesUtils.getSantaPropsSelectorForReactClass(compClass);
        const santaTypesProps = santaPropsSelectorForComp({
            fetchSantaType: (santaTypesDefinition, state, props) => siteAPI.getSantaFetcher(santaTypesDefinition)(state, props),
            siteData,
            siteAPI
        }, {
            structure,
            rootNavigationInfo: rootProps.rootNavigationInfo,
            rootId
        });

        return _.assign(rootProps, santaTypesProps);
    }

    /**
     *
     * @param {data.compStructure} compStructure
     * @param {core.SiteAPI} siteAPI
     * @param {string=} pageId
     * @param viewerPrivateServices
     * @param propsFromParent
     * @return {comp.properties}
     */
    function getCompProps(compClass, compStructure, siteAPI, pageId, viewerPrivateServices, propsFromParent) {
        const siteData = siteAPI.getSiteData();

        const navigationInfo = siteData.getExistingRootNavigationInfo(pageId);

        const compState = {
            fetchSantaType: (santaTypesDefinition, state, props) => siteAPI.getSantaFetcher(santaTypesDefinition)(state, props),
            siteData,
            siteAPI
        };
        const compProps = _.defaults({
            id: compStructure.id,
            structure: compStructure,
            rootNavigationInfo: navigationInfo,
            rootId: pageId
        }, propsFromParent);

        if (LEGACY_PROPS_COMP_TYPES[compStructure.componentType]) {
            return _.assign(getLegacyCompProps(compState, compProps, compClass, viewerPrivateServices), propsFromParent);
        }

        const computedProps = computedPropsBuilder.getInstance(compState, compProps, compClass.propTypes, 'santaTypes', false).getComputedProps(compProps, true);
        return _.assign(computedProps.get(), propsFromParent);
    }

    function getLegacyCompProps(state, props, compClass, viewerPrivateServices = {}) { // eslint-disable-line complexity
        const {siteData, siteAPI} = state;
        const {structure, rootNavigationInfo, rootId} = props;

        const santaPropsSelectorForComp = compClass && santaComponents.utils.santaTypesUtils.getSantaPropsSelectorForReactClass(compClass);
        const santaTypesProps = santaPropsSelectorForComp && santaPropsSelectorForComp(state, props);
        const behaviorsAspect = siteAPI.getSiteAspect('behaviorsAspect');

        const compId = structure.id;
        const displayedDAL = siteAPI.getDisplayedDAL();
        const pointers = siteAPI.getPointers();

        const pagePointer = pointers.page.getPagePointer(rootId);
        const compPointer = pagePointer && pointers.components.getComponent(compId, pagePointer);
        const dataQuery = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['dataQuery'])) : structure.dataQuery;
        const propertyQuery = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['propertyQuery'])) : structure.propertyQuery;
        const designQuery = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['designQuery'])) : structure.designQuery;
        const behaviorQuery = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['behaviorQuery'])) : structure.behaviorQuery;
        const styleId = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['styleId'])) : structure.styleId;
        const skin = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['skin'])) : structure.skin;
        const layout = compPointer ? displayedDAL.get(pointers.getInnerPointer(compPointer, ['layout'])) : structure.layout;

        const componentProps = {
            viewerPrivateServices,
            structure,
            siteData,
            siteAPI,
            id: compId,
            refInParent: compId,
            /**@deprecated */
            pageId: rootId,
            rootId,
            currentUrlPageId: siteData.getCurrentUrlPageId(),
            rootNavigationInfo,
            styleId: propsBuilderUtil.getStyleId(styleId, skin),
            skin: propsBuilderUtil.getSkin(styleId, skin, function (id) {
                return displayedDAL.get(pointers.data.getThemeItem(id, 'masterPage'));
            }),
            style: propsBuilderUtil.getStyle(layout, siteAPI, compId),
            compProp: propsBuilderUtil.getCompProp(siteAPI, propertyQuery, rootId),
            compActions: behaviorsAspect.getActions('comp', compId),
            compBehaviors: propsBuilderUtil.getCompBehaviors(siteAPI, propertyQuery, behaviorQuery, rootId, compId),
            renderFlags: siteData.renderFlags
        };

        if (designQuery) {
            componentProps.compDesign = propsBuilderUtil.getCompDesign(siteAPI, designQuery, rootId);
        }

        if (dataQuery) {
            componentProps.compData = propsBuilderUtil.getCompData(siteAPI, dataQuery, rootId);
        }

        return _.assign(componentProps, santaTypesProps);
    }

    /**
     * @class core.componentPropsBuilder
     */
    const moduleExports = {
        getCompProps,
        getRootProps
    };

    return moduleExports;
});


/**
 * @typedef {object} comp.properties
 * @property {data.compStructure} structure
 * @property {core.SiteData} siteData,
 * @property {core.SiteAPI} siteAPI
 * @property {string} id
 * @property {string} key
 * @property {string} ref
 * @property {string} styleId the style id in the dom
 * @property {string} rootId the page id the comp is on
 * @property {site.rootNavigationInfo} rootNavigationInfo
 * @property {string} skin the name of the skin
 * @property {comp.compLayout} style
 * @property {Object.<string,string>} loadedStyles a map between the data style id to the dom style id
 * @property {data.compDataItem} compData
 * @property {data.compPropertiesItem} compProp
 * @property {object} compState
 * @property {Action[]} compActions
 * @property {object} reactDomProps the props that will be set on the root redData of the component
 * @property {?function ()} onComponentWillUpdate a hook method that a parent component can request to be notified when his child will update
 */



/**
 * @typedef {object} comp.compLayout
 * @property {?number} top
 * @property {?number} left
 * @property {?number} height
 * @property {?number} width
 * @property {string} position
 */
