define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/utils/dataFetchUtil'
], function (
    _,
    PropTypes,
    propsSelectorsUtils,
    dataFetchUtil
) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const activityInfo = createGlobalSantaType(PropTypes.shape({
        currentUrl: PropTypes.object,
        hubSecurityToken: PropTypes.number,
        metaSiteId: PropTypes.string,
        svSession: PropTypes.string,
        baseUrl: PropTypes.string
    }), function (state) {
        return {
            currentUrl: dataFetchUtil.get(state, 'currentUrl'),
            hubSecurityToken: state.siteData.getHubSecurityToken(),
            metaSiteId: state.siteData.getMetaSiteId(),
            svSession: state.siteData.getSvSession(),
            baseUrl: state.siteData.getExternalBaseUrl()
        };
    }, 'activityInfo');


    return {
        activityInfo
    };
});
