define(['prop-types', 'lodash', 'coreUtils', 'santaProps/utils/propsSelectorsUtils', 'santa-components',
    'santaProps/utils/dataFetchUtil'], function (PropTypes, _, coreUtils, propsSelectorsUtils, santaComponents) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const NO_APP_MANIFEST_CODE = -1;

    function getDefaultStageData(siteData) {
        return {icon: coreUtils.media.getMediaUrl(siteData.serviceTopology,
            '/appController/controller-unicorn.svg')};
    }

    function getControllerStageData(state, props) {
	    const controllerId = props.structure.id;
	    const controllerData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
	    const controllerType = controllerData.controllerType;
	    const applicationId = controllerData.applicationId;

	    if (!state.siteAPI.getAppManifest(applicationId)) {
		    return NO_APP_MANIFEST_CODE;
	    }

	    const controllerState = state.siteAPI.getControllerState(controllerId);

	    return state.siteAPI.getControllerStageData(applicationId, controllerType, controllerState);
    }

    const applicativeUIData = createComponentSantaType(PropTypes.shape({icon: PropTypes.string.isRequired}), function (state, props) {
        const controllerStageData = getControllerStageData(state, props);

        if (controllerStageData === NO_APP_MANIFEST_CODE) {
            return null;
        }

        return _.assign({}, getDefaultStageData(state.siteData), controllerStageData);
    });

    const isVisible = createComponentSantaType(PropTypes.bool, function (state, props) {
	    const controllerStageData = getControllerStageData(state, props);
	    const appManifestExists = controllerStageData !== NO_APP_MANIFEST_CODE;
        const showControllers = state.siteAPI.getRenderFlag('showControllers');

        return coreUtils.appManifest.controllerStageData.isControllerVisible(controllerStageData, appManifestExists, showControllers);
    });

    return {
        isVisible,
        applicativeUIData
    };
});
