define(['prop-types', 'santaProps/utils/propsSelectorsUtils'], function (PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const loadBatch = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.store.loadBatch.bind(state.siteData.store);
    }, 'loadBatch');

    return {
        loadBatch
    };
});
