define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'nonPageItemZoom';


    const zoom = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).zoom;
    }, 'zoom');

    const unzoom = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).unzoom;
    }, 'unzoom');

    const currentItem = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getAspectGlobalData('nonPageItemZoom');
    }, 'currentItem');

    return {
        zoom,
        unzoom,
        currentItem
    };
});
