define([
    'lodash',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'compDesignUtils'
], function (
    _,
    PropTypes,
    santaComponents,
    propsSelectorsUtils,
    compDesignUtils
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;

    const containerStyle = createComponentSantaType(PropTypes.object, function (state, props) {
        const compDesign = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props);
        return compDesignUtils.getMediaPlayerContainerStyle(compDesign);
    });

    return {containerStyle};
});

