define(['prop-types', 'santaProps/utils/propsSelectorsUtils'], function (PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const cannotHideIframeWithinRoundedCorners = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.cannotHideIframeWithinRoundedCorners;
    }, 'cannotHideIframeWithinRoundedCorners');

    const isZoomed = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.isZoomed;
    }, 'isZoomed');

    const isZoomedIn = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.isZoomedIn;
    }, 'isZoomedIn');

    const mobileZoomByScreen = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.getMobileZoomByScreenProperties;
    }, 'mobileZoomByScreen');

    const getInvertedZoomRatio = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.getInvertedZoomRatio;
    }, 'getInvertedZoomRatio');

    const isPortrait = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.isPortrait;
    }, 'isPortrait');

    const isLandscape = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.mobile.isLandscape;
    }, 'isLandscape');

    const isAndroidOldBrowser = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.mobile.isAndroidOldBrowser();
    }, 'isAndroidOldBrowser');

    return {
        cannotHideIframeWithinRoundedCorners,
        isZoomed,
        isZoomedIn,
        getInvertedZoomRatio,
        isAndroidOldBrowser,
        isPortrait,
        mobileZoomByScreen,
        isLandscape
    };
});
