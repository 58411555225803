define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils'
], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const urlFormat = propsSelectorsUtils.createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getUrlFormat();
    }, 'urlFormat');

    const getMainPageUrl = propsSelectorsUtils.createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getMainPageUrl.bind(state.siteData);
    }, 'getMainPageUrl');

    const getCurrentUrl = propsSelectorsUtils.createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getCurrentUrl.bind(state.siteData);
    }, 'getCurrentUrl');

    const isZoomOpened = propsSelectorsUtils.createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteAPI.isZoomOpened();
    }, 'isZoomOpened');

    const viewportStates = propsSelectorsUtils.createComponentSantaType(PropTypes.bool, function (state, props) {
        const compId = _.get(props, ['structure', 'id']);
        const viewportAspect = state.siteAPI.getSiteAspect('viewportChange');
        return viewportAspect.get(compId);
    });


    return {
        urlFormat,
        getMainPageUrl,
        getCurrentUrl,
        isZoomOpened,
        viewportStates
    };
});
