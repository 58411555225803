define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const cookie = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state.siteData, 'requestModel.cookie');
    }, 'cookie');

    const language = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state.siteData, 'requestModel.language');
    }, 'language');

    return {
        cookie,
        language
    };
});
