define(['lodash'], function (_) {
    'use strict';

    function getSiteDataPropertyByPath(state, path) {
        const displayedJsonDal = state.siteAPI.getDisplayedDAL();
        path = _.isArray(path) ? path : [path];
        return displayedJsonDal.getByPath(path);
    }

    return {
        get: getSiteDataPropertyByPath
    };
});
