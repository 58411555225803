define([
    'lodash',
    'mobx',
    'coreUtils',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (_, mobx, coreUtils, santaComponents, propsSelectorsUtils) {
    'use strict';

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function getStructure(props) {
        return _.invoke(props.structure, 'get') || props.structure;
    }

    function createLegacySantaTypesComputed(santaType, state, props, propName) {
        if (propsSelectorsUtils.isGlobalSantaType(santaType)) {
            const globalComputedMap = state.siteData.globalComputedMap || {};
            const globalId = propsSelectorsUtils.getGlobalId(santaType);

            if (globalComputedMap[globalId]) {
                return globalComputedMap[globalId];
            }

            const globalComputed = mobx.computed(function () {
                try {
                    return santaType.fetch(state, _.omit(props, ['structure']));
                } catch (e) {
                    coreUtils.log.error('Cannot fetch SantaType', globalId, e);
                }
            }, {name: `globalSantaType_${globalId}`});

            globalComputedMap[globalId] = globalComputed;

            return globalComputed;
        }

        return mobx.computed(function () {
            try {
                return santaType.fetch(state, _.defaults({structure: getStructure(props)}, props));
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', propName, props.id, e);
            }
        }, {name: `${propName}_${props.id}`, compareStructural: propsSelectorsUtils.isCompareStructuralSantaType(santaType)});
    }

    function createComputedFromSantaTypeDefinition(santaTypeDefinition, state, props, propName) {
        const fetcher = state.siteAPI.getSantaFetcher(santaTypeDefinition);
        const globalId = propsSelectorsUtils.getGlobalId(fetcher);
        if (globalId) {
            const globalComputedMap = state.siteData.globalComputedMap || {};
            if (globalComputedMap[globalId]) {
                return globalComputedMap[globalId];
            }

            const globalComputed = mobx.computed(function () {
                try {
                    return state.fetchSantaType(santaTypeDefinition, state, _.omit(props, ['structure']));
                } catch (e) {
                    coreUtils.log.error('Cannot fetch SantaType', globalId, e);
                }
            }, {name: `globalSantaType_${globalId}`});

            globalComputedMap[globalId] = globalComputed;

            return globalComputed;
        }

        return mobx.computed(function () {
            try {
                return state.fetchSantaType(santaTypeDefinition, state, props);
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', propName, props.id, e);
            }
        }, {name: `${propName}_${props.id}`, compareStructural: propsSelectorsUtils.isCompareStructuralSantaType(fetcher)});
    }

    function createComputed(santaTypeOrSantaTypeDefinition, state, props, propName) {
        if (santaTypeOrSantaTypeDefinition.fetch) {
            return createLegacySantaTypesComputed(santaTypeOrSantaTypeDefinition, state, props, propName);
        }

        return createComputedFromSantaTypeDefinition(santaTypeOrSantaTypeDefinition, state, props, propName);
    }

    class ComputedPropsManager {
        constructor(state, props, propTypes, shouldObserveChanges) {
            this.siteAPI = state.siteAPI;
            this.siteData = state.siteData;
            this.observeChanges = shouldObserveChanges;
            this.santaTypes = propTypes ? santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(propTypes) : {};
            this.id = props.id;
            this.state = state;
            this.getComputedProps = this.getComputedProps.bind(this);
            this.getChangedProps = this.getChangedProps.bind(this);
            this.clearChangedPropsMap = this.clearChangedPropsMap.bind(this);
        }

        getComputedProps(props, invalidateCache) {
            if (!invalidateCache) {
                const cachedComputedProps = this.computedPropsFunction;
                if (cachedComputedProps) {
                    return cachedComputedProps;
                }
            }

            const computedPropTypesMap = _.mapValues(this.santaTypes, (santaType, propName) => createComputed(santaType, this.state, props, propName));

            this.clearChangedPropsMap();

            if (this.observeChanges) {
                _.forOwn(computedPropTypesMap, (computed, propName) => {
                    mobx.observe(computed, () => {
                        this.changedProps[propName] = true;
                    });
                });
            }

            this.computedPropsFunction = mobx.computed(function () {
                return _.mapValues(computedPropTypesMap, function (computedPropType) {
                    return computedPropType.get();
                });
            }, {name: `computedProps_${this.id}`});

            return this.computedPropsFunction;
        }

        getChangedProps() {
            return this.changedProps;
        }

        clearChangedPropsMap() {
            this.changedProps = {};
        }
    }

    function getInstance(state, props, santaTypes, santaTypeIdentifier, shouldObserveChanges) {
        let currPrivates = privates.get(state.siteData);
        if (!currPrivates) {
            currPrivates = {};
            privates.set(state.siteData, currPrivates);
        }

        if (!currPrivates.computedPropsBuilderInstanceMap) {
            currPrivates.computedPropsBuilderInstanceMap = {};
        }

        const instanceMap = currPrivates.computedPropsBuilderInstanceMap;

        const structure = getStructure(props) || {};
        const key = `${props.rootId}-${structure.componentType}-${props.id}_${santaTypeIdentifier}`;

        if (!instanceMap[key]) {
            instanceMap[key] = new ComputedPropsManager(state, props, santaTypes, shouldObserveChanges);
        }

        return instanceMap[key];
    }

    return {
        getInstance
    };
});
