define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils', 'coreUtils'], function (_, PropTypes, propsSelectorsUtils, coreUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    function memoizeSiteData(func) {
        return _.memoize(func, function (state) {
            return state.siteData;
        });
    }

    const handleShareRequest = createGlobalSantaType(PropTypes.func, memoizeSiteData(function (state) {
        return coreUtils.socialShareHandler.handleShareRequest.bind(coreUtils.socialShareHandler, state.siteAPI);
    }), 'handleShareRequest');

    const shortenURL = createGlobalSantaType(PropTypes.func, function () {
        return coreUtils.socialShareHandler.shortenURL;
    }, 'shortenURL');

    return {
        handleShareRequest,
        shortenURL
    };
});
