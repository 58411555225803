define([
    'lodash',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/propsBuilder/componentPropsBuilder',
    'santaProps/propsBuilder/ssrComputedPropsBuilder',
    'santaProps/propsBuilder/computedPropsBuilder',
    'santaProps/propsBuilder/propsBuilderUtil',
    'santaProps/fetchers/santaFetchers',
    'santaProps/fetchers/aspects/aspectPropsConnector',
    'santaProps/types/SantaTypes'
], function (
    _,
    propsSelectorsUtils,
    componentPropsBuilder,
    ssrComputedPropsBuilder,
    computedPropsBuilder,
    propsBuilderUtil,
    santaFetchers,
    aspectPropsConnector,
    SantaTypes) {
    'use strict';

    return {
        componentPropsBuilder,
        ssrComputedPropsBuilder,
        computedPropsBuilder,
        propsBuilderUtil,
        createSantaType: propsSelectorsUtils.createComponentSantaType,
        createGlobalSantaType: propsSelectorsUtils.createGlobalSantaType,
        fetchers: {
            get: siteAPI => _.defaultsDeep({}, santaFetchers(siteAPI), SantaTypes)
        },
        aspectPropsConnector,
        Types: SantaTypes
    };
});
