define([
    'lodash',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (_, PropTypes, santaComponents, propsSelectorsUtils) {
    'use strict';

    const ASPECT_NAME = 'vkPostMessage';

    // TODO - Remove this and use the layou from the structure instead.
    const LAYOUT_SIZES = {
        Button: {w: 100, h: 21},
        ButtonWithoutCounter: {w: 64, h: 21},
        Link: {w: 50, h: 21},
        LinkWithoutIcon: {w: 30, h: 21},
        Icon: {w: 32, h: 32}
    };

    const getSizes = function (widthFromVK, style) {
        const size = LAYOUT_SIZES[style] || LAYOUT_SIZES.Button;
        return {
            width: widthFromVK || size.w,
            height: size.h
        };
    };

    const size = propsSelectorsUtils.createComponentSantaType(PropTypes.shape({width: PropTypes.number}), function (state, props) { //eslint-disable-line santa/no-module-state
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const widthFromVK = _.get(state.siteAPI.getAspectComponentData(ASPECT_NAME, props.structure.id), 'width');
        return getSizes(widthFromVK, compData.style);
    }, true);

    return {
        size
    };
});
