define([
    'lodash',
    'prop-types',
    'coreUtils',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (
    _,
    PropTypes,
    coreUtils,
    santaComponents,
    propsSelectorsUtils
) {
    'use strict';

    const siteDataUtils = coreUtils.siteDataUtils;

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const dynamicActions = createGlobalSantaType(PropTypes.array, function (state) {
        const siteData = state.siteAPI.getSiteData();
        const isLandingPage = siteData.isPageLandingPage(siteData.getPrimaryPageId());
        const dynamicActionsFromAspect = isLandingPage ? [] : state.siteAPI.getAspectGlobalData('QuickActionBarAspect').dynamicActions;
        return _.map(dynamicActionsFromAspect, function (action) {
            const actionData = _.find(coreUtils.constants.SUPPORTED_DYNAMIC_ACTIONS, {appId: action.appId});
            return _.assign(
                _.pick(actionData, ['text', 'color', 'itemType']),
                _.pick(action, ['enabled', 'notificationCount', 'appId', 'color']),
                {svgString: siteDataUtils.getSvgString(state.siteData, `svgshape.v2.Svg_${actionData.icon}`)});
        });
    }, 'dynamicActions');

    const structuredAction = createComponentSantaType(PropTypes.object, function (state, props) {
        const actionData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const themeDataItem = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.theme, state, props);
        const styleColor = _.get(themeDataItem, ['style', 'properties', 'color']);

        return _.assign({},
            _.pick(actionData, ['text', 'link', 'itemType']),
            {color: styleColor},
            {svgString: siteDataUtils.getSvgString(state.siteData, `svgshape.v2.Svg_${actionData.icon}`)});
    });

    return {
        dynamicActions,
        structuredAction
    };
});
