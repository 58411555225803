define(['lodash',
    'santaProps/utils/contactForm/ContactFormSantaTypesConfig.json'],
function (_, contactFormConfig) {
    'use strict';

    function getOldModelFieldKey(fieldName) {
        return `${fieldName}FieldLabel`;
    }

    function createFieldsFromModel(compData, compProp, skin) {
        const orderedFields = contactFormConfig.OLD_MODEL_FIELDS_ORDERED_BY_SKIN[skin] || [];
        return orderedFields.map(function (fieldName, index) {
            const fieldKey = getOldModelFieldKey(fieldName);
            return {
                index,
                fieldType: fieldName,
                fieldLabel: compData[fieldKey],
                required: compProp[`required_${fieldKey}`],
                hidden: compProp[`hidden_${fieldKey}`]
            };
        });
    }

    function preprocessCompProp(compProp) {
        return _.isEmpty(compProp) ? _.clone(contactFormConfig.OLD_MODEL_DEFAULT_PROPS) : compProp;
    }

    return {
        preprocessCompProp,
        createFieldsFromModel
    };
});
