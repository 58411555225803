define([
    'lodash',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'coreUtils'
], function (
    _,
    PropTypes,
    santaComponents,
    propsSelectorsUtils,
    coreUtils
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;

    const siteMenuWithRender = createComponentSantaType(PropTypes.array, function (state, props) {
        return coreUtils.menuUtils.getSiteMenuWithRender(state.siteData, props.rootNavigationInfo, false);
    });

    const menuItems = createComponentSantaType(PropTypes.array, function (state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const menuId = compData && compData.menuRef ? compData.menuRef.id : 'CUSTOM_MAIN_MENU';
        return coreUtils.menuUtils.getMenuById(menuId, state.siteData, false, props.rootNavigationInfo, false);
    });


    return {
        siteMenuWithRender,
        menuItems
    };
});
