define([
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/commonSantaTypes'
], function (
    PropTypes,
    santaComponents,
    propsSelectorsUtils,
    commonSantaTypes
) {
    'use strict';

    return {
        CurrentPageSocialUrl: propsSelectorsUtils.createComponentSantaType(PropTypes.string, function (state, props) {
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);

            const santaPropsUrlFormat = commonSantaTypes.urlFormat.fetch(state, props);
            const urlFormat = compData ? compData.urlFormat : santaPropsUrlFormat;
            const pageUrl = commonSantaTypes.getCurrentUrl.fetch(state, props)(urlFormat, undefined, urlFormat !== santaPropsUrlFormat);

            const httpsOK = compData && compData.isHttpsEnabled;
            return httpsOK ? pageUrl : pageUrl.replace(/^https:/, 'http:');
        }),

        MainPageSocialUrl: propsSelectorsUtils.createComponentSantaType(PropTypes.string, function (state, props) {
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);

            const santaPropsUrlFormat = commonSantaTypes.urlFormat.fetch(state, props);
            const urlFormat = compData ? compData.urlFormat : santaPropsUrlFormat;
            const pageUrl = commonSantaTypes.getMainPageUrl.fetch(state, props)(urlFormat);

            const httpsOK = compData && compData.isHttpsEnabled;
            return httpsOK ? pageUrl : pageUrl.replace(/^https:/, 'http:');
        })
    };
});
