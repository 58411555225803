define(['prop-types', 'santaProps/utils/propsSelectorsUtils'], function (PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const isTabletDevice = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.isTabletDevice;
    }, 'isTabletDevice');

    const isDesktopDevice = createGlobalSantaType(PropTypes.func, function (state) {
        return () => !state.siteData.isTouchDevice();
    }, 'isDesktopDevice');

    return {
        isTabletDevice,
        isDesktopDevice
    };
});
