define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'addComponent';

    const addComponent = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.addComponent;
    }, 'addComponent');

    const deleteComponent = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(aspectName);
        return aspect.deleteComponent;
    }, 'deleteComponent');

    return {
        addComponent,
        deleteComponent
    };
});
