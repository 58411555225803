define([
    'lodash',
    'coreUtils',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (_, coreUtils, santaComponents, propsSelectorsUtils) {
    'use strict';


    function getStructure(props) {
        return _.invoke(props.structure, 'get') || props.structure;
    }

    function getSsrSantaTypesByDefinition(santaTypeDefinition, propName, state, props) {
        const fetcher = state.siteAPI.getSantaFetcher(santaTypeDefinition);
        const globalId = propsSelectorsUtils.getGlobalId(fetcher);
        if (globalId) {
            try {
                return state.fetchSantaType(santaTypeDefinition, state, _.omit(props, ['structure']));
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', globalId, e);
            }
        } else {
            try {
                return state.fetchSantaType(santaTypeDefinition, state, _.defaults({structure: getStructure(props)}, props));
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', propName, props.id, e);
            }
        }
    }

    function getSsrLegacySantaTypes(santaType, propName, state, props) {
        if (propsSelectorsUtils.isGlobalSantaType(santaType)) {
            const globalId = propsSelectorsUtils.getGlobalId(santaType);

            try {
                return santaType.fetch(state, _.omit(props, ['structure']));
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', globalId, e);
            }
        } else {
            try {
                return santaType.fetch(state, _.defaults({structure: getStructure(props)}, props));
            } catch (e) {
                coreUtils.log.error('Cannot fetch SantaType', propName, props.id, e);
            }
        }
    }

    function getSsrProps(compClass, state, props) {
        const propTypesOfComp = santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(compClass.propTypes);

        return _.mapValues(propTypesOfComp, function (santaType, propName) {
            if (santaType.fetch) {
                return getSsrLegacySantaTypes(santaType, propName, state, props);
            }

            return getSsrSantaTypesByDefinition(santaType, propName, state, props);
        });
    }

    return {
        getSsrProps
    };
});
