define(['lodash'],
    function (_) {
        'use strict';

        function createFieldsFromModel(compData) {
            const fieldsModel = compData.dynamicFields || [];
            return fieldsModel.map(function (field, index) {
                const res = {index};
                if (field) {
                    _.assign(res, {
                        fieldType: field.name,
                        fieldLabel: field.displayLabel,
                        required: field.required,
                        hidden: true
                    });
                } else {
                    _.assign(res, {
                        required: false,
                        hidden: false
                    });
                }
                return res;
            });
        }

        function isContactFormNewModel(compData) {
            const dynamicFields = _.get(compData, 'dynamicFields');
            return dynamicFields && !_.isEmpty(dynamicFields);
        }

        return {
            isNewModel: isContactFormNewModel,
            createFieldsFromModel
        };
    });
