define([
    'lodash',
    'prop-types',
    'coreUtils',
    'santa-components',
    'santaProps/types/modules/SiteMembersSantaTypes',
    'santaProps/utils/propsSelectorsUtils'
], function (
    _,
    PropTypes,
    coreUtils,
    santaComponents,
    SiteMembersSantaTypes,
    propsSelectorsUtils
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const PREVIEW_STATES = {
        LOGGED_IN: 'loggedIn',
        LOGGED_IN_MENU_OPENED: 'loggedInMenuOpened',
        LOGGED_OUT: 'loggedOut'
    };
    const LOGIN_DATA_ITEM_TYPE = 'LoginSocialBar';

    function getCompData(state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        if (compData) {
            if (compData.type === LOGIN_DATA_ITEM_TYPE) {
                return compData;
            }

            if (compData.loginSocialBarRef) {
                return compData.loginSocialBarRef;
            }
        }
        return null;
    }

    function hasLoginData(state, props) {
        const compData = getCompData(state, props);
        return _.isObject(compData) && compData.type === LOGIN_DATA_ITEM_TYPE;
    }

    function resolveMenuItems(state, props, fieldNameInSchema) {
        if (hasLoginData(state, props)) {
            const compData = getCompData(state, props);
            const menuItems = _.get(compData, [fieldNameInSchema, 'menuRef', 'items'], null);
            if (_.isArray(menuItems)) {
                return coreUtils.menuUtils.getMenuItems(menuItems, state.siteData, true, props.rootNavigationInfo, false);
            }
        }
        return null;
    }

    function isLoggedInPreviewState(state, props) {
        if (state.siteData.isViewerMode()) {
            return false;
        }

        const componentViewMode = state.fetchSantaType(santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode, state, props);
        const previewState = state.fetchSantaType(santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState, state, props);
        return (componentViewMode === 'editor' || componentViewMode === 'preview') && !_.includes(previewState, PREVIEW_STATES.LOGGED_OUT);
    }

    function isOwnerOrMemberLoggedIn(state, props) {
        return isLoggedInPreviewState(state, props) || SiteMembersSantaTypes.isLoggedIn.fetch(state, props);
    }

    const language = createComponentSantaType(PropTypes.string, function (state, props) {
        const compData = getCompData(state, props);
        return _.get(compData, 'language') || 'en';
    });

    const isConnectedToLoginData = createComponentSantaType(PropTypes.bool, function (state, props) {
        return hasLoginData(state, props);
    });

    const isLoggedIn = createComponentSantaType(PropTypes.bool, function (state, props) {
        return isOwnerOrMemberLoggedIn(state, props);
    });

    const memberName = createComponentSantaType(PropTypes.string, function (state, props) { // eslint-disable-line complexity
        const compData = getCompData(state, props);
        let name = '';

        if (hasLoginData(state, props) && isOwnerOrMemberLoggedIn(state, props)) {
            name = compData.showLoggedInText ? `${compData.loggedInText} ` : '';

            const memberDetails = isLoggedInPreviewState(state, props) ? SiteMembersSantaTypes.memberDetailsInPreview.fetch(state, props) : SiteMembersSantaTypes.memberDetails.fetch(state, props);
            if (memberDetails) {
                name += _.get(memberDetails, 'attributes.nickname') || _.get(memberDetails, 'attributes.name') || memberDetails.name || memberDetails.email;
            }
        }
        return name;
    });

    const memberAvatar = createComponentSantaType(PropTypes.string, function (state, props) {
        let avatarUrl = null;
        if (hasLoginData(state, props) && isOwnerOrMemberLoggedIn(state, props)) {
            const memberDetails = isLoggedInPreviewState(state, props) ? SiteMembersSantaTypes.memberDetailsInPreview.fetch(state, props) : SiteMembersSantaTypes.memberDetails.fetch(state, props);
            avatarUrl = _.get(memberDetails, 'attributes.imageUrl', null);
        }
        return avatarUrl;
    });

    const memberDefaultAvatar = createComponentSantaType(PropTypes.string, function (state, props) {
        if (hasLoginData(state, props)) {
            const compData = getCompData(state, props);
            if (compData.loggedInMember !== 'textOnly') {
                return '3d84bae5ad4d4d8a96de15e9f4b79a08.svg';
            }
        }
        return null;
    });

    const menuItems = createComponentSantaType(PropTypes.array, function (state, props) {
        return resolveMenuItems(state, props, 'menuItemsRef');
    });

    const iconItems = createComponentSantaType(PropTypes.array, function (state, props) {
        return resolveMenuItems(state, props, 'iconItemsRef');
    });

    return {
        language,
        memberName,
        memberAvatar,
        memberDefaultAvatar,
        isLoggedIn,
        isConnectedToLoginData,
        menuItems,
        iconItems
    };
});
