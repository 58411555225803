define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils', 'santa-components'], function (_, PropTypes, propsSelectorsUtils, santaComponents) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const ASPECT_NAME = 'InlinePopupAspect';
    const BEHAVIOR_NAME = 'toggle';

    const open = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(ASPECT_NAME);
        return id => aspect.open(id);
    }, 'open');

    const close = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(ASPECT_NAME);
        return aspect.close;
    }, 'close');

    const isOpen = createComponentSantaType(PropTypes.bool, function (state, props) {
        const aspectData = state.siteAPI.getAspectGlobalData(ASPECT_NAME);
        return _.get(aspectData, ['openedId']) === props.id;
    });

    const isTargetOpen = createComponentSantaType(PropTypes.bool, function (state, props) {
        const compBehaviors = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compBehaviors, state, props);
        const toggleBehavior = _.find(compBehaviors, ['behavior.name', BEHAVIOR_NAME]);
        if (!toggleBehavior) {
            return false;
        }

        const targetID = _.get(toggleBehavior, 'behavior.targetId');
        const aspectData = state.siteAPI.getAspectGlobalData(ASPECT_NAME);
        const openedId = _.get(aspectData, ['openedId']);
        return openedId === targetID;
    });

    return {
        open,
        close,
        isOpen,
        isTargetOpen
    };
});
