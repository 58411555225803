define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/utils/dataFetchUtil'
], function (
    _,
    PropTypes,
    propsSelectorsUtils,
    dataFetchUtil
) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const aspectName = 'siteMembers';
    const POLICIES_MODEL_KEYS = ['termsOfUse', 'privacyPolicy', 'codeOfConduct'];

    function getOpenDialog(state) {
        const siteMembersAspect = state.siteAPI.getSiteAspect(aspectName);
        return siteMembersAspect.aspectState.read('dialogToDisplay');
    }

    const smSettings = createGlobalSantaType(PropTypes.object, function (state) {
        const smSettingsData = _.get(state.siteData.getDataByQuery('masterPage'), ['smSettings'], {});
        _.forEach(POLICIES_MODEL_KEYS, function (policy) {
            if (smSettingsData[policy] && smSettingsData[policy].link) {
                const linkData = state.siteData.getDataByQuery(smSettingsData[policy].link);
                _.set(smSettingsData, [policy, 'linkData'], linkData);
            }
        });
        return smSettingsData;
    }, 'smSettings');

    const isSiteMembersDialogsOpenAllowed = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteAPI.getRenderFlag('isSiteMembersDialogsOpenAllowed');
    }, 'isSiteMembersDialogsOpenAllowed');

    const siteMembersUrl = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getServiceTopologyProperty('siteMembersUrl');
    }, 'siteMembersUrl');

    const forgotPasswordToken = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['currentUrl', 'query', 'forgotPasswordToken']);
    }, 'forgotPasswordToken');

    const dialogToDisplay = createGlobalSantaType(PropTypes.string, getOpenDialog, 'dialogToDisplay');

    const isSiteMembersDialogOpen = createGlobalSantaType(PropTypes.bool, state => !!getOpenDialog(state), 'isSiteMembersDialogOpen');

    const getMemberDetails = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).getMemberDetails;
    }, 'getMemberDetails');

    const memberDetails = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).getMemberDetails();
    }, 'getMemberDetails');

    const memberDetailsInPreview = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).getMemberDetailsInPreview();
    }, 'getMemberDetails');

    const isLoggedIn = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).isLoggedIn();
    }, 'isLoggedIn');

    const logout = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).logout;
    }, 'logout');

    const showAuthenticationDialog = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).showAuthenticationDialog;
    }, 'showAuthenticationDialog');

    const reportSiteMembersBi = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).reportSiteMembersBi;
    }, 'reportSiteMembersBi');

    const registerToMemberDetailsChange = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).registerToMemberDetailsChange;
    }, 'registerToMemberDetailsChange');

    const unRegisterMemberDetailsChange = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect(aspectName).unRegisterMemberDetailsChange;
    }, 'unRegisterMemberDetailsChange');

    return {
        smSettings,
        isSiteMembersDialogsOpenAllowed,
        siteMembersUrl,
        forgotPasswordToken,
        dialogToDisplay,
        isSiteMembersDialogOpen,
        isLoggedIn,
        logout,
        showAuthenticationDialog,
        getMemberDetails,
        memberDetails,
        memberDetailsInPreview,
        reportSiteMembersBi,
        registerToMemberDetailsChange,
        unRegisterMemberDetailsChange
    };
});
