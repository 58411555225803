define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const updateUrlIfNeeded = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.updateUrlIfNeeded;
    }, 'updateUrlIfNeeded');

    const href = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.href;
    }, 'href');

    const navigateToRenderedLink = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.navigateToRenderedLink;
    }, 'navigateToRenderedLink');

    return {
        updateUrlIfNeeded,
        href,
        navigateToRenderedLink
    };
});
