define([
    'lodash',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (
    _,
    PropTypes,
    santaComponents,
    propsSelectorsUtils
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;

    const markVisitedPage = createComponentSantaType(PropTypes.func, function (state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        if (!_.get(compData, 'isPopup')) {
            return state.siteAPI.markVisitedPage;
        }
        //return nothing, we dont want to handle popups now
    });

    return {
        markVisitedPage
    };
});
