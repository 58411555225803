/*eslint max-statements: ["error", 100]*/

define([
    'lodash',
    'prop-types',
    'coreUtils',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/DangerousSantaTypes',
    'santaProps/types/modules/VerticalAnchorsMenuSantaTypes',
    'santaProps/types/modules/DeviceSantaTypes',
    'santaProps/types/modules/HtmlPostMessage',
    'santaProps/types/modules/PinterestSantaTypes',
    'santaProps/types/modules/MemberLoginSantaTypes',
    'santaProps/types/modules/AnchorChangeSantaTypes',
    'santaProps/types/modules/AppControllerSantaTypes',
    'santaProps/types/modules/RenderFlagsSantaTypes',
    'santaProps/types/modules/MobileTypes',
    'santaProps/types/modules/NavigationTypes',
    'santaProps/types/modules/SiteAspectsSantaTypes',
    'santaProps/types/modules/RequestModelSantaTypes',
    'santaProps/types/modules/PublicModelSantaTypes',
    'santaProps/types/modules/AspectComponentSantaTypes',
    'santaProps/types/modules/SocialShareHandlerSantaTypes',
    'santaProps/types/modules/ActivitySantaTypes',
    'santaProps/types/modules/MediaSantaTypes',
    'santaProps/types/modules/MenuSantaTypes',
    'santaProps/types/modules/ContactFormSantaTypes',
    'santaProps/types/modules/SiteMembersSantaTypes',
    'santaProps/types/modules/RendererModelTypes',
    'santaProps/types/modules/AudioSantaTypes',
    'santaProps/types/modules/StoreSantaTypes',
    'santaProps/types/modules/QuickActionsSantaTypes',
    'santaProps/types/modules/NonPageItemZoom',
    'santaProps/types/modules/TPASantaTypes',
    'santaProps/types/modules/PageSantaTypes',
    'santaProps/types/modules/CompCssDesignSantaTypes',
    'santaProps/types/modules/MediaPlayerContainerStyleSantaTypes',
    'santaProps/types/modules/repeaterSantaTypes',
    'santaProps/types/modules/loginButtonSantaTypes',
    'santaProps/types/modules/commonSantaTypes',
    'santaProps/types/modules/socialSantaTypes',
    'santaProps/types/modules/vkSantaTypes',
    'santaProps/types/modules/SlideShowSantaTypes',
    'santaProps/types/modules/JsonLd',
    'santaProps/types/modules/InlinePopupSantaTypes'
], function (_,
             PropTypes,
             coreUtils,
             santaComponents,
             propsSelectorsUtils,

             DangerousSantaTypes,
             VerticalAnchorsMenuSantaTypes,
             DeviceSantaTypes,
             HtmlPostMessage,
             PinterestSantaTypes,
             MemberLoginSantaTypes,
             AnchorChangeSantaTypes,
             AppControllerSantaTypes,
             RenderFlagsSantaTypes,
             MobileTypes,
             NavigationTypes,
             SiteAspectsSantaTypes,
             RequestModelSantaTypes,
             PublicModelSantaTypes,
             AspectComponentSantaTypes,
             SocialShareHandlerSantaTypes,
             ActivitySantaTypes,
             MediaSantaTypes,
             MenuSantaTypes,
             ContactFormSantaTypes,
             SiteMembersSantaTypes,
             RendererModelTypes,
             AudioSantaTypes,
             StoreSantaTypes,
             QuickActionsSantaTypes,
             NonPageItemZoom,
             TPASantaTypes,
             PageSantaTypes,
             CompCssDesignSantaTypes,
             MediaPlayerContainerStyleSantaTypes,
             repeaterSantaTypes,
             loginButtonSantaTypes,
             commonSantaTypes,
             socialSantaTypes,
             vkSantaTypes,
             SlideShowSantaTypes,
             JsonLd,
             InlinePopupSantaTypes) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const handleBehavior = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.getSiteAspect('behaviorsAspect').handleBehavior;
    }, 'handleBehavior');

    const globalImageQuality = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteData.getGlobalImageQuality();
    }, 'globalImageQuality');

    const closePopupPage = createGlobalSantaType(PropTypes.func, function (state) {
        return _.ary(state.siteAPI.getSiteAspect('PopupPageAspect').closePopupPage, 0);
    }, 'closePopupPage');

    const openPopup = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.openPopup;
    }, 'openPopup');

    const scrollToAnchor = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.scrollToAnchor;
    }, 'scrollToAnchor');

    const navigateToPage = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.navigateToPage;
    }, 'navigateToPage');

    const passClickEvent = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.passClickEvent;
    }, 'passClickEvent');

    const getScreenWidth = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getScreenWidth;
    }, 'getScreenWidth');

    const getScreenHeight = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getScreenHeight;
    }, 'getScreenHeight');

    const getScrollBarWidth = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getScrollBarWidth;
    }, 'getScrollBarWidth');

    const forceBackground = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.forceBackground;
    }, 'forceBackground');

    const disableForcedBackground = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.disableForcedBackground;
    }, 'disableForcedBackground');

    const currentPopupId = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getCurrentPopupId();
    }, 'currentPopupId');

    const primaryPageId = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getPrimaryPageId();
    }, 'primaryPageId');

    const mainPageId = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getMainPageId();
    }, 'mainPageId');

    const reportBeatEvent = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.reportBeatEvent;
    }, 'reportBeatEvent');

    const biVisitorId = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteAPI.getBiVisitorId();
    }, 'biVisitorId');

    const isFacebookSite = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isFacebookSite();
    }, 'isFacebookSite');

    const pageUrl = createComponentSantaType(PropTypes.string, function (state) {
        return state.siteAPI.getPageUrl();
    });

    const pageUrlWithHash = createComponentSantaType(PropTypes.string, function (state) {
        return state.siteAPI.getPageUrl(true);
    });

    const isPremiumUser = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isPremiumUser();
    }, 'isPremiumUser');

    const isTemplate = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isTemplate();
    }, 'isTemplate');

    const biData = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteData.biData;
    }, 'biData');

    const origin = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getOrigin();
    }, 'origin');

    const isViewerMode = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isViewerMode();
    }, 'isViewerMode');

    const getExistingRootNavigationInfo = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getExistingRootNavigationInfo.bind(state.siteData);
    }, 'getExistingRootNavigationInfo');

    const getClientSpecMapEntry = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getClientSpecMapEntry.bind(state.siteData);
    }, 'getClientSpecMapEntry');

    const enterFullScreenMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.enterFullScreenMode;
    }, 'enterFullScreenMode');

    const exitFullScreenMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.exitFullScreenMode;
    }, 'exitFullScreenMode');

    const enterOverflowHiddenMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.enterOverflowHiddenMode;
    }, 'enterOverflowHiddenMode');

    const exitOverflowHiddenMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.exitOverflowHiddenMode;
    }, 'exitOverflowHiddenMode');

    const enterMediaZoomMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.enterMediaZoomMode;
    }, 'enterMediaZoomMode');

    const exitMediaZoomMode = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.exitMediaZoomMode;
    }, 'exitMediaZoomMode');

    const triggerFakeModeChange = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.triggerFakeModeChange.bind(state.siteAPI);
    }, 'triggerFakeModeChange');

    const viewerSessionId = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.wixBiSession.viewerSessionId;
    }, 'viewerSessionId');

    const isSinglePostPage = createComponentSantaType(PropTypes.bool, function (state, props) {
        const documentData = state.siteData.getPageDataMap(props.rootId, state.siteData.dataTypes.DATA);
        return _.some(documentData, {appPartName: coreUtils.blogAppPartNames.SINGLE_POST});
    });

    const rootPageTitle = createComponentSantaType(PropTypes.object, function (state, props) {
        return state.siteData.getPageData(props.rootId, ['title']);
    });

    const getUniquePageId = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteData.getUniquePageId.bind(state.siteData);
    }, 'getUniquePageId');

    const isInSSR = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isInSSR();
    }, 'isInSSR');

    const isClientAfterSSR = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isClientAfterSSR();
    }, 'isClientAfterSSR');

    const isFirstRenderAfterSSR = createComponentSantaType(PropTypes.bool, function (state) {
        return state.siteData.isFirstRenderAfterSSR();
    });

    const compFactoryRuntimeState = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteData.compFactoryRuntimeState;
    }, 'compFactoryRuntimeState');

    const resetCustomClickOccurred = createGlobalSantaType(PropTypes.func, function (state) {
        return state.siteAPI.resetCustomClickOccurred;
    }, 'resetCustomClickOccurred');

    const shouldEnableMobileAnimations = createGlobalSantaType(PropTypes.bool, function (state) {
        const defaultAnimationsSettings = {
            animationsEnabled: true
        };
        const mobileSettingsData = _.get(state.siteData.getDataByQuery('masterPage'), ['mobileSettings'], defaultAnimationsSettings);
        return mobileSettingsData.animationsEnabled;
    }, 'animationsEnabled');

    const pageMinHeightDefault = createGlobalSantaType(PropTypes.number, function (state) {
        return state.siteData.getPageMinHeight();
    }, 'pageMinHeightDefault');

    const setCompState = createGlobalSantaType(PropTypes.func, function (state) {
        const runtimeDal = state.siteAPI.getRuntimeDal();
        return runtimeDal.setCompState;
    }, 'setCompState');

    const setCompData = createGlobalSantaType(PropTypes.func, function (state) {
        const runtimeDal = state.siteAPI.getRuntimeDal();
        return runtimeDal.setCompData;
    }, 'setCompData');

    const setCompProps = createGlobalSantaType(PropTypes.func, function (state) {
        const runtimeDal = state.siteAPI.getRuntimeDal();
        return runtimeDal.setCompProps;
    }, 'setCompProps');

    const removeCompState = createGlobalSantaType(PropTypes.func, function (state) {
        const runtimeDal = state.siteAPI.getRuntimeDal();
        return runtimeDal.removeCompState;
    }, 'removeCompState');

    const isTpaRenderedInSsr = createComponentSantaType(PropTypes.bool, function (state, props) {
        const {tpasRenderedInSsr} = state.siteData;
        return (tpasRenderedInSsr && tpasRenderedInSsr[props.id]) === true;
    });

    const isGoogleBot = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isGoogleBot();
    }, 'isGoogleBot');

    const isCacheable = createGlobalSantaType(PropTypes.bool, function (state) {
        return state.siteData.isCacheable();
    }, 'isCacheable');

    const SantaTypes = {
        __DangerousSantaTypes: DangerousSantaTypes,
        Audio: AudioSantaTypes,
        InlinePopup: InlinePopupSantaTypes,
        TPA: TPASantaTypes,
        AspectComponent: AspectComponentSantaTypes,
        SocialShareHandler: SocialShareHandlerSantaTypes,
        Device: DeviceSantaTypes,
        VerticalAnchorsMenu: VerticalAnchorsMenuSantaTypes,
        HtmlPostMessage,
        Pinterest: PinterestSantaTypes,
        MemberLogin: MemberLoginSantaTypes,
        AnchorChange: AnchorChangeSantaTypes,
        AppController: AppControllerSantaTypes,
        RenderFlags: RenderFlagsSantaTypes,
        Activity: ActivitySantaTypes,
        mobile: MobileTypes,
        Navigation: NavigationTypes,

        CompDesign: CompCssDesignSantaTypes,
        MediaPlayerDesign: MediaPlayerContainerStyleSantaTypes,
        Media: MediaSantaTypes,
        Menu: MenuSantaTypes,
        JsonLd,

        urlFormat: commonSantaTypes.urlFormat,
        getMainPageUrl: commonSantaTypes.getMainPageUrl,
        getCurrentUrl: commonSantaTypes.getCurrentUrl,
        biData,
        biVisitorId,
        isViewerMode,
        globalImageQuality,
        getScreenWidth,
        getScreenHeight,
        getScrollBarWidth,
        forceBackground,
        disableForcedBackground,
        currentPopupId,
        isTemplate,
        isPremiumUser,
        Behaviors: {
            handleBehavior
        },
        DAL: {
            setCompState,
            setCompData,
            setCompProps,
            removeCompState
        },
        popupPage: {
            close: closePopupPage
        },
        popup: {
            open: openPopup
        },
        NonPageItemZoom,
        scrollToAnchor,
        navigateToPage,
        passClickEvent,
        RendererModel: RendererModelTypes,
        RequestModel: RequestModelSantaTypes,
        PublicModel: PublicModelSantaTypes,
        isZoomOpened: commonSantaTypes.isZoomOpened,
        isFacebookSite,
        SiteAspects: SiteAspectsSantaTypes,
        Location: {
            origin
        },
        primaryPageId,
        mainPageId,
        reportBeatEvent,
        getExistingRootNavigationInfo,
        pageUrl,
        pageUrlWithHash,
        getClientSpecMapEntry,
        enterFullScreenMode,
        exitFullScreenMode,
        enterOverflowHiddenMode,
        exitOverflowHiddenMode,
        enterMediaZoomMode,
        exitMediaZoomMode,
        Modes: {
            triggerFakeModeChange
        },
        staticValue: coreUtils.staticValue,
        ContactFormSantaTypes,
        SiteMembersSantaTypes,
        StoreSantaTypes,
        QuickActions: QuickActionsSantaTypes,
        viewerSessionId,
        isSinglePostPage,
        rootPageTitle,
        getUniquePageId,
        Page: PageSantaTypes,
        viewportStates: commonSantaTypes.viewportStates,
        isInSSR,
        isClientAfterSSR,
        isFirstRenderAfterSSR,
        isTpaRenderedInSsr,
        isGoogleBot,
        compFactoryRuntimeState,
        resetCustomClickOccurred,
        shouldEnableMobileAnimations,
        Repeater: repeaterSantaTypes,
        LoginButton: loginButtonSantaTypes,
        Social: socialSantaTypes,
        VK: vkSantaTypes,
        SlideShow: SlideShowSantaTypes,
        pageMinHeightDefault,
        isCacheable
    };

    return _.defaultsDeep({}, santaComponents.santaTypesDefinitions, SantaTypes);
});
