define(['lodash', 'prop-types', 'coreUtils', 'santa-components', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, coreUtils, santaComponents, propsSelectorsUtils) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const VERTICAL_ANCHORS_MENU_ASPECT_NAME = 'VerticalAnchorsMenuAspect';
    const WINDOW_SCROLL_EVENT_ASPECT_NAME = 'windowScrollEvent';

    function findOverlappingBrightness(menuId, colorsInfoMap, measureMap, siteX, scrollY) {
        const menuTop = scrollY + measureMap.absoluteTop[menuId];
        const menuHeight = measureMap.height[menuId];
        const menuLeft = measureMap.absoluteLeft[menuId];
        const menuWidth = measureMap.width[menuId];

        const overlappingColorInfo = _(colorsInfoMap)
            .omitBy(function (colorInfo) {
                return !colorInfo.layout || colorInfo.alpha < 0.1;
            })
            .filter(function (colorInfo) {
                const colorInfoLayout = colorInfo.layout;
                const menuMinTopToOverlapStrip = colorInfoLayout.top - menuHeight / 2; // eslint-disable-line no-mixed-operators
                const menuMaxTopToOverlapStrip = colorInfoLayout.top + colorInfoLayout.height - menuHeight / 2; // eslint-disable-line no-mixed-operators
                return menuTop >= menuMinTopToOverlapStrip &&
                    menuTop <= menuMaxTopToOverlapStrip &&
                    menuLeft + menuWidth / 2 <= colorInfoLayout.left + colorInfoLayout.width && // eslint-disable-line no-mixed-operators
                    menuLeft >= colorInfoLayout.left + siteX;
            })
            .sortBy(function (colorInfo) {
                return colorInfo.layout.width * colorInfo.layout.height;
            })
            .head();

        return _.get(overlappingColorInfo, 'brightness', null);
    }

    function getHiddenPageAnchorIds(compData, pageId) {
        let hiddenAnchors = null;
        if (compData.hiddenAnchorIds && pageId) {
            hiddenAnchors = compData.hiddenAnchorIds[pageId];
        }
        return hiddenAnchors;
    }

    const updateInformation = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.updateInformation;
    }, 'updateInformation');

    const updateImageInfo = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.updateImageInfo;
    }, 'updateImageInfo');

    const getOverlappingBackgroundBrightness = createComponentSantaType(PropTypes.func, function (state, props) {
        return function () {
            const measureMap = state.siteData.measureMap;

            if (!measureMap) {
                return null;
            }

            const menuId = props.structure.id;
            const pageColorInfoMaps = _.get(state.siteAPI.getAspectGlobalData(VERTICAL_ANCHORS_MENU_ASPECT_NAME), 'colorInfo', {});
            const colorsInfoByComponents = _.assign({}, pageColorInfoMaps.masterPage, pageColorInfoMaps[state.siteData.getFocusedRootId()]);
            const scrollPos = state.siteAPI.getAspectGlobalData(WINDOW_SCROLL_EVENT_ASPECT_NAME) || {x: 0, y: 0};
            const siteX = state.siteData.getSiteX();

            return findOverlappingBrightness(menuId, colorsInfoByComponents, measureMap, siteX, scrollPos.y);
        };
    });

    const activeAnchor = createComponentSantaType(PropTypes.object, function (state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const currentPage = state.siteData.getPrimaryPageId();
        const hiddenAnchorsCompIds = _.get(compData, ['hiddenAnchorIds', currentPage], []);
        if (state.siteData.isInSSR()) {
            return coreUtils.scrollAnchors.getHighestAnchorByStructure(state.siteData, 0, hiddenAnchorsCompIds);
        }
        const allAnchorsData = _.get(state.siteAPI.getAspectGlobalData(VERTICAL_ANCHORS_MENU_ASPECT_NAME), 'anchorsData', []);
        const shownAnchors = _.reject(allAnchorsData, anchor => _.includes(hiddenAnchorsCompIds, anchor.activeAnchorComp.compId));

        return _.every(shownAnchors, {
            activeAnchorComp: {
                y: 0
            }
        }) ? null : _.findLast(shownAnchors, 'isActive');
    }, true);

    const getAnchorLinkItems = createComponentSantaType(PropTypes.func, function (state, props) {
        return function () {
            const primaryPageId = state.siteData.getPrimaryPageId();
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
            const allAnchors = coreUtils.scrollAnchors.getPageAnchors(state.siteData, primaryPageId, compData.pageTopLabel);
            const hiddenAnchors = getHiddenPageAnchorIds(compData, primaryPageId);
            const menuItems = _.filter(allAnchors, function (anchor) {
                return !_.includes(hiddenAnchors, anchor.compId);
            });
            if (_.size(menuItems) === 0) {
                menuItems.push(allAnchors[0]);
            }

            return _.map(menuItems, function (originalAnchorMenuItem) {
                const anchorLinkItem = _.clone(originalAnchorMenuItem);
                anchorLinkItem.type = 'AnchorLink';
                anchorLinkItem.anchorDataId = originalAnchorMenuItem;
                anchorLinkItem.pageId = state.siteData.getDataByQuery(primaryPageId); // will not work if we enable Anchors in popups.

                return anchorLinkItem;
            });
        };
    });

    const registerToMeanColor = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.registerToMeanColor;
    }, 'registerToMeanColor');

    const unregisterToMeanColor = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.unregisterToMeanColor;
    }, 'unregisterToMeanColor');

    const registerToActiveAnchor = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.registerToActiveAnchor;
    }, 'registerToActiveAnchor');

    const unregisterToActiveAnchor = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(VERTICAL_ANCHORS_MENU_ASPECT_NAME);
        return aspect.unregisterToActiveAnchor;
    }, 'unregisterToActiveAnchor');

    return {
        updateInformation,
        updateImageInfo,
        registerToMeanColor,
        unregisterToMeanColor,
        getOverlappingBackgroundBrightness,
        activeAnchor,
        getAnchorLinkItems,
        registerToActiveAnchor,
        unregisterToActiveAnchor
    };
});
