define([
    'lodash',
    'coreUtils',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils'
], function (_,
             coreUtils,
             santaComponents,
             propsSelectorsUtils) {
    'use strict';


    /**
     * Get locations array with translated link properties
     * @returns {{}|null}
     */
    const getLocations = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        if (_.get(data, 'locations')) {
            const linkRenderInfo = state.fetchSantaType(santaComponents.santaTypesDefinitions.Link.renderInfo, state, props);
            const rootNavigationInfo = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.rootNavigationInfo, state, props);
            const locations = _.clone(data.locations);
            return _.map(locations, location => _.assign({}, location, {locationLinkAttributes: coreUtils.linkRenderer.renderLink(location.link, linkRenderInfo, rootNavigationInfo)}));
        }
    });

    return {
        locations: getLocations
    };
});
